@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Jost:ital,wght@0,100..900;1,100..900&display=swap");

:root {
  --backgroundColour: #fdf8ee;
  --logoColor: #88cfe5;
  --textColor: #fc984f;
  --grayColor: #8a8a8a;
  --violetColor: #4d2c5e;
  --jost: "Jost", sans-serif;
  --roboto: "Roboto", sans-serif;
  --margin: 30px;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}


/* nav section */

.navbar {
  background-color: var(--backgroundColour);
  color: #fff;
  padding: 1rem;
}

.navbar-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: var(--jost);
}


.logo{
  width: 50px;
  height: 50px;
  margin-left: 20px;
}
.navbar-menu {
  display: block;
}

.navbar-menu.open {
  display: block;
}

.navbar-nav {
  list-style: none;
  margin: 0; 
  display: flex;
  padding-right: 0px;
}

.nav-item {
  font-weight: 500;
  padding-left: 20px;
  text-transform: uppercase;
}

.navbar-toggle {
  display: none;
  background: none;
  border: none;
  cursor: pointer;
}

a{
  text-decoration: none;
  color: black;
}

/* hero section */

.hero-section {
  background-color: var(--backgroundColour);
  width: 100%;
  height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.text-container {
  width: 50%;
  padding: 20px 50px;
}
.text-container > h2 {
  font-family: var(--roboto);
  font-size: 2rem;
  font-weight: 700;
  word-break: normal;
}
.text-container > h2 span {
  color: var(--textColor);
}
.disc {
  margin-top: 20px;
  color: var(--grayColor);
}
.img-container {
  width: 50%;
  height: 100%;
}
.img-container > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 4px;
}

/* about section */

.about-section,
.course-section {
  width: 100%;
  height: auto;
  background-color: #fff;
  text-align: center;
  margin-top: var(--margin);
}
.about-items,
.course-details {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
}
.about-section > h2,
.course-section > h2 {
  text-align: center;
  margin-bottom: 30px;
  font-family: var(--jost);
  text-transform: uppercase;
}
.float-right {
  float: right;
}
.float-left {
  float: left;
}
.about-mission {
  width: 80%;
  margin: 0 50px;
}
.about-mission img {
  width: 400px;
  height: 250px;
  margin: 0 10px;
  object-fit: cover;
  border-radius: 4px;
}
.mission-text {
  font-family: var(--roboto);
}
.mission-text h2 {
  margin: 20px;
  font-weight: 500;
  font-style: oblique;
}
.mission-text p {
  font-size: 0.9rem;
  padding: 0 20px;
}
#abt-img{
  object-fit: contain;
}

/* course-section */

.courses h4 {
  font-family: var(--roboto);
  font-size: 1.5rem;
  font-weight: 500;
  padding: 20px 0;
}
.courses p {
  font-family: var(--roboto);
  font-size: 1rem;
  padding: 10px 0;
}

/* admission section */

.admission-section {
  width: 100%;
  height: auto;
  margin-top: var(--margin);
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}
.admission {
  max-width: 90%;
}
.admission > img {
  width: 100%;
  height: 250px;
  object-fit: fill;
}
.admission h2 {
  font-family: var(--jost);
  text-align: center;
  margin: 20px 0;
}

.list h4 {
  margin: 20px 0;
  font-size: 1.1rem;
  font-family: var(--roboto);
}
ul {
  padding-left: 30px;
}
.list ul li {
  line-height: 1.5;
  font-size: 1.1rem;
}

/* contact section */

.contact-section,
.socialMedia {
  width: 100%;
  height: auto;
  margin-top: var(--margin);
  background-color: #fff;
}
.contact-details {
  padding: 0 150px;
}
.contact-details h2 {
  text-align: center;
  text-transform: uppercase;
  font-family: var(--jost);
}
.contact-details h4 {
  font-family: var(--roboto);
  font-size: 1.5rem;
  font-weight: 400;
  padding: 20px 0;
}
.contact-details p {
  width: auto;
  height: auto;
  line-height: 1.5;
}
.contact-details .btn {
  display: inline-block;
  margin: 20px 0;
  padding: 10px;
  font-size: 1.2rem;
  font-family: var(--jost);
  font-weight: 500;
  border-radius: 4px;
  outline: 0;
  background-color: #fff;
  border: 1px solid var(--grayColor);
  cursor: pointer;
  transition: background-color 0.5s ease;
}
.contact-details .btn:hover {
  color: #fff;
  background-color: black;
  border: none;
}

/* Gallary */

.photos-container{
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
 
}
.photos{
  width: 90%;
}
.image{
  width: 425px;
  margin: 10px;
  border-radius: 4px;
}
/* social media */

.socialMedia h2 {
  text-align: center;
  text-transform: uppercase;
  font-family: var(--jost);
}
.socialMedia .links {
  text-align: center;
  margin: 30px 0;
}
.icon {
  width: 50px;
  height: 30px;
  padding-left: 20px;
  cursor: pointer;
}

/* footer */

.footer {
  background-color: var(--backgroundColour);
  width: 100%;
  height: 100px;
  margin-top: var(--margin);
  text-align: center;
}
.footer p {
  padding-top: 20px;
}

/* media queries */

@media (max-width: 768px) {
  .about-mission {
    width: 90%;
    margin: 0 0px;
  }
  .about-mission img {
    width: 300px;
  }

  /* admission */

  .admission-section {
    width: 100%;
    height: auto;
  }
  .admission {
    max-width: 90%;
  }
  .admission > img {
    height: 200px;
    object-fit: fill;
  }

  /* contact */

  .contact-details {
    padding: 0 20px;
  }
  .contact-details p {
    width: auto;
  }

  /* gallary */

  
  .image{
    width: 300px;
    margin: 10px;
  }
}

/* media queries 425px */

@media (max-width: 425px) {
  .navbar-menu {
    display: none;
    position: absolute;
    top: 12%;
    right: 0;
    width: 100%;
    background-color: var(--backgroundColour);
  }
  .navbar-nav {
    list-style: none;
    margin: 0; 
    display: block;
    padding-right: 0px;
  }
  .nav-item {
    padding: 10px;
  }
  .navbar-menu.open {
    display: block;
  }

  .navbar-toggle {
    display: block;
    font-size: 30px;
  }

 

  /* hero section */


  .hero-section {
  flex-direction: column-reverse;
}
.text-container {
  width: auto;
  padding: 10px 20px;
}
.text-container > h2 {
  font-size: 1.8rem;
  font-weight: 600;
}
.img-container {
  width: auto;
  padding: 10px 20px;
}

/* about */

.about-mission {
  width: 90%;
  margin: 0 0px;
}
.about-mission img {
  width: 100%;
  height: 150px;
  margin: 0 0px;
}
.float-right {
  float: none;
}
.float-left {
  float: none;
}

.admission > img {
  height: 110px;
  object-fit: fill;
}
.photos-container{
  margin-top: var(--margin);
}
.photos{
  width: 90%;
}
.image{
  width: 100%;
  margin: 0px;
}
}